@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Neue Haas Grotesk Display Pro', Arial, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen';
  font-size: 15px;
}

@screen dl {
  html {
    font-size: 16px;
  }
}

body {
  background: white;
}

/* ------------------ Neue ------------------  */

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('./resources/fonts/NeueHaasGrotesk/NeueHaasDisplay-Light.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('./resources/fonts/NeueHaasGrotesk/NeueHaasDisplay-Roman.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('./resources/fonts/NeueHaasGrotesk/NeueHaasDisplay-Mediu.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('./resources/fonts/NeueHaasGrotesk/NeueHaasDisplay-Bold.ttf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* ------------------ Manrope ------------------  */

@font-face {
  font-family: 'Manrope';
  src: url('./resources/fonts/Manrope/Manrope-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./resources/fonts/Manrope/Manrope-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./resources/fonts/Manrope/Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./resources/fonts/Manrope/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./resources/fonts/Manrope/Manrope-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* ------------------ Digital-7 ------------------  */

@font-face {
  font-family: 'Digital-7 Mono';
  src: url('./resources/fonts/digital-7/digital-7-mono.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Digital-7';
  src: url('./resources/fonts/digital-7/digital-7.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@layer utilities {
  .link-boxShadow {
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.05);
  }

  .headerLinks > .active,
  .headerLinks .active + a:not(.soc) {
    border-color: transparent !important;
  }

  .headerLinks .active + a.soc {
    border-top: 1px solid transparent !important;
  }

  .blockShadow {
    box-shadow: 0px 4px 4px rgba(65, 113, 185, 0.08),
      0px 11px 15px rgba(65, 113, 185, 0.06),
      0px 30px 40px rgba(65, 113, 185, 0.03);
  }

  .blockShadow-cardHover {
    box-shadow: 0px 4px 4px rgba(65, 113, 185, 0.08),
      0px 15px 15px rgba(65, 113, 185, 0.12),
      0px 40px 50px rgba(65, 113, 185, 0.2);
  }

  .incubatedByShadow {
    box-shadow: 0px 4px 4px rgba(65, 113, 185, 0.08),
      0px 11px 15px rgba(65, 113, 185, 0.06),
      0px 30px 40px rgba(65, 113, 185, 0.03);
  }

  .walletButtonShadow {
    box-shadow: 0px 4px 4px rgba(65, 113, 185, 0.08),
      0px 15px 15px rgba(65, 113, 185, 0.12),
      0px 40px 50px rgba(65, 113, 185, 0.2);
  }

  .block-gradient {
    background: radial-gradient(
          circle at 100% 100%,
          #f4f5f7 0,
          #f4f5f7 9px,
          transparent 9px
        )
        0% 0%/10px 10px no-repeat,
      radial-gradient(circle at 0 100%, #f4f5f7 0, #f4f5f7 9px, transparent 9px)
        100% 0%/10px 10px no-repeat,
      radial-gradient(circle at 100% 0, #f4f5f7 0, #f4f5f7 9px, transparent 9px)
        0% 100%/10px 10px no-repeat,
      radial-gradient(circle at 0 0, #f4f5f7 0, #f4f5f7 9px, transparent 9px)
        100% 100%/10px 10px no-repeat,
      linear-gradient(#f4f5f7, #f4f5f7) 50% 50% / calc(100% - 2px)
        calc(100% - 20px) no-repeat,
      linear-gradient(#f4f5f7, #f4f5f7) 50% 50% / calc(100% - 20px)
        calc(100% - 2px) no-repeat,
      linear-gradient(0deg, #dae3f0 0%, #ffffff 100%);
    border-radius: 10px;
  }

  .button-gradient-border {
    background: radial-gradient(
          circle at 100% 100%,
          #f4f5f7 0,
          #f4f5f7 5px,
          transparent 5px
        )
        0% 0%/6px 6px no-repeat,
      radial-gradient(circle at 0 100%, #f4f5f7 0, #f4f5f7 5px, transparent 5px)
        100% 0%/6px 6px no-repeat,
      radial-gradient(circle at 100% 0, #f4f5f7 0, #f4f5f7 5px, transparent 5px)
        0% 100%/6px 6px no-repeat,
      radial-gradient(circle at 0 0, #f4f5f7 0, #f4f5f7 5px, transparent 5px)
        100% 100%/6px 6px no-repeat,
      linear-gradient(#f4f5f7, #f4f5f7) 50% 50% / calc(100% - 2px)
        calc(100% - 12px) no-repeat,
      linear-gradient(#f4f5f7, #f4f5f7) 50% 50% / calc(100% - 12px)
        calc(100% - 2px) no-repeat,
      linear-gradient(0deg, #5891ff 0%, #dfebff 100%);
    border-radius: 6px;
    padding: 11px;
    box-sizing: content-box;
  }

  .user-tier-gradient {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.77),
        rgba(255, 255, 255, 0.77)
      ),
      linear-gradient(342.83deg, #afffcf 1.6%, #d2e1ff 61.28%, #fafbff 114.89%);
    border-radius: 10px;
  }

  .tier-olympus-gradient {
    background: linear-gradient(
        331.51deg,
        rgba(30, 128, 255, 0.44) 4.64%,
        rgba(83, 158, 255, 0.0924) 103.61%
      ),
      #ffffff;
  }

  .tier-nemea-gradient {
    background: linear-gradient(
        336.03deg,
        rgba(118, 165, 255, 0.22) 6.04%,
        rgba(118, 165, 255, 0) 88.92%
      ),
      #f4f5f7;
  }

  .high-tier-user-gradient {
    background: linear-gradient(
        342.83deg,
        rgba(175, 255, 207, 0.5) 1.6%,
        rgba(210, 225, 255, 0.5) 61.28%,
        rgba(250, 251, 255, 0.5) 114.89%
      ),
      linear-gradient(
        336.03deg,
        rgba(118, 165, 255, 0.22) 6.04%,
        rgba(118, 165, 255, 0) 88.92%
      ),
      #f4f5f7;
  }

  .tier-dots:before {
    content: '';
    position: absolute;
    bottom: 0.1rem;
    width: 100%;
    height: 0;
    line-height: 0;
    border-bottom: 2px dotted #646464;
    opacity: 0.6;
  }

  .vesting-gradient {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.77),
        rgba(255, 255, 255, 0.77)
      ),
      linear-gradient(270.14deg, #afffcf 0.16%, #d2e1ff 54.4%, #fafbff 103.11%);
  }

  .no-scrollbar {
    scrollbar-width: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .markdown {
    @apply font-neue text-[1rem] leading-[150%];
  }

  .markdown p {
    @apply my-[1rem];
  }

  .markdown strong {
    @apply font-medium;
  }

  .markdown ul {
    @apply my-[1rem];
  }

  .markdown ul li {
    @apply relative pl-[1.5rem] leading-[150%];
    padding-left: 1.5rem;
  }

  .markdown ul li::before {
    content: '';
    position: absolute;
    left: 0.5rem;
    top: 0.6rem;
    background: #000;
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 0.3rem;
  }

  .markdown img {
    @apply my-[1rem];
  }

  .markdown iframe.video,
  .markdown video {
    width: 100%;
    height: 28rem;
  }

  .dashboard-card-gradient-1 {
    background: repeating-linear-gradient(
      45deg,
      rgba(244, 243, 235, 0.1),
      rgba(244, 243, 235, 0.1) 10px,
      transparent 10px,
      transparent 20px
    );
  }

  .home-page-greenGradient {
    background-image: linear-gradient(
      45deg,
      #34deb3 25%,
      #35ebbc 25%,
      #35ebbc 50%,
      #34deb3 50%,
      #34deb3 75%,
      #35ebbc 75%,
      #35ebbc 100%
    );
    background-size: 56.57px 56.57px;
  }

  .home-page-upcomingGradient {
    background-image: linear-gradient(
      45deg,
      #a6a6a6 2.17%,
      #f4f3eb 2.17%,
      #f4f3eb 50%,
      #a6a6a6 50%,
      #a6a6a6 52.17%,
      #f4f3eb 52.17%,
      #f4f3eb 100%
    );
    background-size: 32.53px 32.53px;
  }

  .home-page-noDataGradient {
    background-image: linear-gradient(
      45deg,
      #eff0f1 25%,
      #ebecee 25%,
      #ebecee 50%,
      #eff0f1 50%,
      #eff0f1 75%,
      #ebecee 75%,
      #ebecee 100%
    );
    background-size: 56.57px 56.57px;
  }

  table {
    border-spacing: 0;
  }

  table th {
    font-weight: 400;
  }

  .vesting-unlock-shadow {
    box-shadow: 0px 0px 12px rgba(255, 133, 251, 0.6);
  }

  .finishedPoolsTable-shadow {
    box-shadow: 0px 4px 4px rgba(65, 113, 185, 0.08),
      0px 11px 15px rgba(65, 113, 185, 0.06),
      0px 30px 40px rgba(65, 113, 185, 0.03);
  }

  .toast-shadow {
    box-shadow: 0px 4px 4px rgba(65, 113, 185, 0.08),
      0px 15px 15px rgba(65, 113, 185, 0.12),
      0px 40px 50px rgba(65, 113, 185, 0.2);
  }

  .chakra-item-gradient {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.77),
        rgba(255, 255, 255, 0.77)
      ),
      linear-gradient(270.14deg, #afffcf 0.16%, #d2e1ff 54.4%, #fafbff 103.11%);
    box-shadow: 0px 4px 4px rgba(65, 113, 185, 0.08),
      0px 11px 15px rgba(65, 113, 185, 0.06),
      0px 30px 40px rgba(65, 113, 185, 0.03);
  }

  .spinner {
    animation: rotator 1.4s linear infinite;
    stroke: #5891ff;
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  .spinner-path {
    transform-origin: center;
    animation: dash 1.4s ease-in-out infinite;
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }

  .place-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }
}

.react-tooltip {
  color: #393f4f !important;
  font-size: 0.8rem !important;
  line-height: 140%;
  background: white !important;
  box-shadow: 0rem 0.1rem 0.6rem rgba(0, 0, 0, 0.1);
  opacity: 1 !important;
  text-transform: initial !important;
  padding: 0.5rem 0.6rem !important;
  z-index: 1000 !important;
  max-width: 13rem !important;
  border-radius: 0.3rem !important;
  box-shadow: 0px 0.2rem 0.2rem rgba(65, 113, 185, 0.08),
    0px 11px 15px rgba(65, 113, 185, 0.06),
    0px 30px 40px rgba(65, 113, 185, 0.03);
}
.react-tooltip .react-tooltip-arrow {
  display: none;
}

.react-tooltip.blue {
  color: white !important;
  font-size: 14px !important;
  line-height: 140%;
  background: #5891ff !important;
  box-shadow: 0px 30px 40px 0px rgba(65, 113, 185, 0.03),
    0px 11px 15px 0px rgba(65, 113, 185, 0.06),
    0px 4px 4px 0px rgba(65, 113, 185, 0.08);
  text-transform: initial !important;
  padding: 12px 15px !important;
  z-index: 1000 !important;
  max-width: 350px !important;
  border-radius: 8px !important;
}
.vesting-schedule-item-gradient {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.77),
      rgba(255, 255, 255, 0.77)
    ),
    linear-gradient(270.14deg, #afffcf 0.16%, #d2e1ff 54.4%, #fafbff 103.11%);
}

.vesting-schedule-item-gradient-hover:hover {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.77),
      rgba(255, 255, 255, 0.77)
    ),
    linear-gradient(270.14deg, #afffcf 0.16%, #d2e1ff 54.4%, #fafbff 103.11%);
}

.wallet-button-hasTier-gradient {
  background: linear-gradient(
    180deg,
    rgba(244, 245, 247, 0) 16.25%,
    rgba(244, 245, 247, 0.9604) 82.82%
  );
}

.tier-card-gradient {
  background: linear-gradient(
    90deg,
    #e8eff4 63.39%,
    rgba(232, 239, 244, 0) 96.64%
  );
}

.dotted-decoration {
  background-image: linear-gradient(to right, #5891ff 75%, transparent 75%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 5px 1px;
}
